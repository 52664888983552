<template>
  <b-container fluid>
    <bo-page-title />
    <div class="dashboard">
      <b-row>
        <b-col md=6>
          <b-row class="justify-content-center gutter-2">
            <b-col lg="6">
              <div class="card_dashboard bg_card_1">
                <b-row>
                  <b-col lg="4">
                    <div class="wrap_ic_dsb">
                      <i class="ti-layout-list-thumb"></i>
                    </div>
                  </b-col>
                  <b-col lg="8">
                    <div class="wrap_total_sts">
                      <h3>{{ total_prospect }}</h3>
                      <p>Total Prospect</p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="card_dashboard bg_card_3">
                <b-row>
                  <b-col lg="4">
                    <div class="wrap_ic_dsb">
                      <i class="ti-layout-list-thumb"></i>
                    </div>
                  </b-col>
                  <b-col lg="8">
                    <div class="wrap_total_sts">
                      <h3>{{ total_survey }}</h3>
                      <p>Total Survey</p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="card_dashboard bg_card_2">
                <b-row>
                  <b-col lg="4">
                    <div class="wrap_ic_dsb">
                      <i class="ti-layout-list-thumb"></i>
                    </div>
                  </b-col>
                  <b-col lg="8">
                    <div class="wrap_total_sts">
                      <h3>{{ total_customer }}</h3>
                      <p>Total Customers</p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="card_dashboard bg_card_4">
                <b-row>
                  <b-col lg="4">
                    <div class="wrap_ic_dsb">
                      <i class="ti-layout-list-thumb"></i>
                    </div>
                  </b-col>
                  <b-col lg="8">
                    <div class="wrap_total_sts">
                      <h3>{{ total_running }}</h3>
                      <p>Running Projects</p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col md=6>
          <b-card >
            <b-row >
              <b-col md="6" v-if="target_tahun">
                <div class="d-flex flex-column justify-content-around h-100">
                  <div>
                    <span>Revenue of {{ yearNow }} <strong :class="target_tahun.is_achieve ? 'text-success' : 'text-danger'">({{ target_tahun.percentage }}%)</strong></span>
                    <div class="revenue-count">
                      <h3 class="font-weight-bold">{{formatRupiah(target_tahun['mt_achieve'] || 0)}}</h3>
                      <span>Target Revenue:</span>
                      <h4 style="font-weight: 500;">{{formatRupiah(target_tahun['mt_target'] || 0)}}</h4>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="6" class="border-left" v-if="target_bulan">
                <div class="d-flex flex-column justify-content-around h-100">
                  <div>
                    <span>Revenue of {{monthYearNow | moment('MMMM YYYY')}} <strong :class="target_bulan.is_achieve ? 'text-success' : 'text-danger'">({{ target_bulan.percentage }}%)</strong></span>
                    <h3 class="font-weight-bold">{{formatRupiah(target_bulan.mt_achieve || 0)}}</h3>
                    <span>Target Revenue:</span>
                    <h4 style="font-weight: 500;">{{formatRupiah(target_bulan.mt_target || 0)}}</h4>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-card >
            <b-row >
              <b-col md="6">
                <div class="d-flex flex-column justify-content-around h-100">
                  <div>
                    <span>Expense in {{ yearNow }}</span>
                    <div class="revenue-count">
                      <h4 class="font-weight-bold">{{formatRupiah(expense_year || 0)}}</h4>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="6" class="border-left">
                <div class="d-flex flex-column justify-content-around h-100">
                  <div>
                    <span>Expense in {{monthYearNow | moment('MMMM YYYY')}}</span>
                    <h4 class="font-weight-bold">{{formatRupiah(expense_month || 0)}}</h4>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <div class="mt-3">
        <b-card no-body header-class="border-0">
          <template #header>
            <h5 class="card-title">Monthly Revenue</h5>
          </template>
          <VCharts :option="revenueChart" class="chart" />
        </b-card>
      </div>
      <b-card no-body>
        <template #header>
          <h5 class="card-title">Upcoming Survey Schedules</h5>
        </template>
        <b-table
          :fields="schFields"
          :items="upcoming_schedule.data || []"
          :busy="!upcoming_schedule.data"
          responsive
          hover
          show-empty
          striped
        >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No Upcoming Schedule listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
          <template #cell(number)="v">
            {{(upcoming_schedule.per_page*(upcoming_schedule.current_page-1))+v.index+1}}
          </template>
          <template #cell(tss_date)="data">
            {{data.value | moment('LLL')}}
          </template>
          <template #cell(tp_client_name)="data">
            <div><small>{{ data.item.tp_code }}</small></div>
            <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
            <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
            <a v-if="data.item.tp_client_phone" :href="'tel: '+data.item.tp_client_phone" class="contact-info"><i class="icon-phone"></i><span>{{ data.item.tp_client_phone }}</span></a>
          </template>
          <template #cell(tss_address)="data">
            <div><small>PIC: {{ data.item.tss_pic_name }}</small></div>
            <div>{{ data.value }}</div>
          </template>
          <template #cell(tss_status)="data">
          <b-badge
            :variant="surveyBadge[data.value].color"
          >
            {{ surveyBadge[data.value].word }}
          </b-badge>
        </template>
        </b-table>
        <template #footer v-if="upcoming_schedule.total > numberShow">
          <b-row class="justify-content-center">
            <b-button type="button" @click="showAllSurvey" variant="transparent" class="btn-rounded text-primary">Show All</b-button>
          </b-row>
        </template>
      </b-card>
      <b-card no-body>
        <template #header>
          <h5 class="card-title">Follow Up Today</h5>
        </template>
        <b-table
          :fields="followUpField"
          :items="followUp || []"
          :busy="!followUp"
          responsive
          hover
          show-empty
          striped
        >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No Follow Up Today listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
          <template #cell(number)="v">
            {{v.index+1}}
          </template>
          <template #cell(tp_client_name)="data">
            <div><small>{{ data.item.tp_code }}</small></div>
            <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
            <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
            <a v-if="data.item.tp_client_phone" :href="'tel: '+data.item.tp_client_phone" class="contact-info"><i class="icon-phone"></i><span>{{ data.item.tp_client_phone }}</span></a>
          </template>
          <template #cell(tfu_date)="data">
            {{ data.value | moment('LL') }}
          </template>
          <template #cell(tp_client_address)="data">
            <div>{{ data.value }}</div>
          </template>
          <template #cell(tp_status)="data">
          <b-badge :variant="prospectBadge[data.value].color">
            {{ prospectBadge[data.value].word }}
          </b-badge>
        </template>
        </b-table>
      </b-card>
    </div>

    <b-modal id="allSurvey" title="Upcoming Survey Schedules" ok-only ok-title="Close" size="xl">
      <b-card no-body>
        <b-table
          :fields="schFields"
          :items="allSurvey || []"
          :busy="!allSurvey"
          responsive
          hover
          show-empty
          striped
        >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No Upcoming Schedule listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
          <template #cell(number)="v">
            {{+v.index+1}}
          </template>
          <template #cell(tss_date)="data">
            {{data.value | moment('LLL')}}
          </template>
          <template #cell(tp_client_name)="data">
            <div><small>{{ data.item.tp_code }}</small></div>
            <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
            <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
            <a v-if="data.item.tp_client_phone" :href="'tel: '+data.item.tp_client_phone" class="contact-info"><i class="icon-phone"></i><span>{{ data.item.tp_client_phone }}</span></a>
          </template>
          <template #cell(tss_address)="data">
            <div><small>PIC: {{ data.item.tss_pic_name }}</small></div>
            <div>{{ data.value }}</div>
          </template>
          <template #cell(tss_status)="data">
          <b-badge
            :variant="surveyBadge[data.value].color"
          >
            {{ surveyBadge[data.value].word }}
          </b-badge>
        </template>
        </b-table>
      </b-card>
    </b-modal>
    
  </b-container>
</template>
<style lang="scss" scoped>
  .chart{
    height: 480px;
  }
</style>
<script>
import GlobalVue from '../../libs/Global.vue'
import VCharts from 'vue-echarts'
import Gen from '@/libs/Gen'
import { use } from 'echarts/core'
import {
 SVGRenderer
} from 'echarts/renderers'
import {
  BarChart
} from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'

use([
  SVGRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
])

export default {
  extends: GlobalVue,
  data() {
    return {
      series: {},
      numberShow: 0,
      schFields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'tp_client_name', label: 'Customer Name',
        },
        {
          key: 'tss_date', label: 'Survey Date',
        },
        {
          key: 'tss_address', label: 'Survey Adress', 
        },
        {
          key: 'tp_keperluan', label: 'Purpose', 
        },
        {
          key: 'tss_status', label: 'Status', 
          },
      ],
      followUpField: [
        {
          key: 'number', label: '#'
        },
        {
          key: 'tp_client_name', label: 'Client Name',
        },
        {
          key: 'tfu_date', label: 'Date',
        },
        {
          key: 'tp_client_address', label: 'Address', 
        },
        {
          key: 'tp_keperluan', label: 'Purpose', 
        },
      ],
      upcoming_schedule: [],
      target_tahun: {},
      target_bulan: {},
      total_prospect: 0,
      total_running: 0,
      total_customer: 0,
      total_survey: 0,
      expense_year: 0,
      expense_month: 0,
      yearNow: this.$route.query.year || new Date().getFullYear(),
      followUp: [],
      allSurvey: []
    }
  },
  computed: {
    stockChart() {
      return this.$refs.stockChart
    },
    monthYearNow(){
      const date = new Date()
      date.setFullYear(this.$route.query.year || new Date().getFullYear())
      date.setMonth(this.$route.query.month ? (this.$route.query.month - 1) : new Date().getMonth())
      return date
    },
    revenueChart(){
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: data => {
            function round(num, decimalPlaces = 0) {
                var p = Math.pow(10, decimalPlaces)
                var n = (num * p) * (1 + Number.EPSILON)
                return Math.round(n) / p
            }

            let percentData = false
            if(data[0] && data[1]){
              if(data[0].seriesName == 'Actual Revenue' && data[1].seriesName == 'Target Revenue'){
                percentData = round((data[0].data/data[1].data)*100, 2)
              }
            }

            let text = ''
            if(data[0]){
              text = `<div class="mb-2"><strong>${data[0].name+' '+this.yearNow}</strong></div>
                <div class="tooltip-content">`
              text += `<div class="d-flex mb-1">
                    <div class="mr-1">
                      ${data[0].marker}
                    </div>
                    <div>
                      ${data[0].seriesName}
                      <strong class="text-right d-block ${data[0].data >= data[1].data ? 'text-success' : 'text-danger'}">
                        ${this.formatRupiah(data[0].data)} ${percentData !== false ?  '('+ percentData + '%)' : ''}
                      </strong>
                    </div>
                  </div>`
            }
            if(data[1]){
              text += `<div class="d-flex">
                  <div class="mr-1">
                    ${data[1].marker}
                  </div>
                  <div>
                    ${data[1].seriesName}
                    <strong class="text-right d-block">
                      ${this.formatRupiah(data[1].data)}
                    </strong>
                  </div>
                </div>`
            }
            if(data[2]){
              text += `<div class="d-flex">
                  <div class="mr-1">
                    ${data[2].marker}
                  </div>
                  <div>
                    ${data[2].seriesName}
                    <strong class="text-right d-block">
                      ${this.formatRupiah(data[2].data)}
                    </strong>
                  </div>
                </div>`
            }

            text += '</div>'
            return text
          }
        },
        legend: {
          data: ['Actual Revenue', 'Target Revenue', 'Expense']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            data: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: this.series
      }
    }
  },
  methods: {
    showAllSurvey(){
      Gen.apiRest('/get/' + this.modulePage + '/getAll').then(res => {
        this.allSurvey = res.data.allSurvey
        this.$bvModal.show('allSurvey')
      })
    }
  },
	components: {
   VCharts,
	},
  mounted() {
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()  
    }
  },
}
</script>